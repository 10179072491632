
import { BASE_URL, MODULE_NUMBER_LIST_URL, MODULE_NUMBER_LIST_SUCCESS, MODULE_NUMBER_LIST_ERROR,
  ADD_UNLOCK_REQUEST_URL, ADD_UNLOCK_REQUEST_SUCCESS, ADD_UNLOCK_REQUEST_ERROR,
  GET_UNLOCK_LIST_URL, GET_UNLOCK_LIST_SUCCESS, GET_UNLOCK_LIST_ERROR,
  GET_PACKAGE_MODULE_LIST_URL, GET_PACKAGE_MODULE_LIST_SUCCESS, GET_PACKAGE_MODULE_LIST_ERROR
} from '../constants/constants';

import { axiosInstance } from '../../utlis';

const moduleNumberListAction = () => async (dispatch) => {
  try {
    const res = await axiosInstance.post(`${BASE_URL}${MODULE_NUMBER_LIST_URL}`);
    if (res.data.success) {
      dispatch({
        type: MODULE_NUMBER_LIST_SUCCESS,
        payload: res.data.body,
      });
    } else {
      dispatch({
        type: MODULE_NUMBER_LIST_ERROR,
        payload: { error: 'Request failed', details: res.data.message || 'Unknown error' },
      });
    }
    return res.data;
  } catch (error) {
    console.error('Error:', error);
    return { error: 'Network error' };
  }
};


const addUnlockRequestAction = (data) => async (dispatch) => {
  try {
    const res = await axiosInstance.post(`${BASE_URL}${ADD_UNLOCK_REQUEST_URL}`, data);
    if (res.data.success) {
      dispatch({
        type: ADD_UNLOCK_REQUEST_SUCCESS,
        payload: res.data.body,
      });

      return res.data.message;
    } else {
      dispatch({
        type: ADD_UNLOCK_REQUEST_ERROR,
        payload: res.data,
      });
    }
  } catch (error) {
    console.error('Error in:', error);
    throw error; 
  }
};


// const addUnlockRequestAction = (data) => async (dispatch) => {
//   try {
//     const res = await axiosInstance.post(
//       `${BASE_URL}${ADD_UNLOCK_REQUEST_URL}`, data
//     );
//     if (res.data.success) {
//       dispatch({
//         type: ADD_UNLOCK_REQUEST_SUCCESS,
//         payload: res.data.body,
//       });
//     } else {
//       dispatch({
//         type: ADD_UNLOCK_REQUEST_ERROR,
//         payload: res.data,
//       });
//     }

//     return res.data;
//   } catch (error) {
//     if (error.response) {
//       return error.response.data;
//     } else {
//       return error;
//     }
//   }
// };


const getUnlockListAction = () => async (dispatch) => {
  try {
    const res = await axiosInstance.post(
      `${BASE_URL}${GET_UNLOCK_LIST_URL}`
    );
    if (res.data.success) {
      dispatch({
        type: GET_UNLOCK_LIST_SUCCESS,
        payload: res.data.body
      });
    } else {
      dispatch({
        type: GET_UNLOCK_LIST_ERROR,
        payload: res.data
      });
    }
    return res.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    } else {
      return error;
    }
  }
};



export {
  moduleNumberListAction,
  addUnlockRequestAction,
  getUnlockListAction
};
