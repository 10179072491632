import {HOLIDAY_LIST_SUCCESS} from '../constants/constants';

const initialState = {
  holidayListingData: {
    data: {},
    success: false
  },
};

const holidayListReducer = (state = initialState, action) => {
  switch (action.type) {
    case HOLIDAY_LIST_SUCCESS:
      return {
        ...state,
        holidayListingData: {
          ...state.holidayListingData,
          data: action.payload
        }
      };
    default:
      return state;
  }
};

export default holidayListReducer;
