import React, {
  Fragment,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import styles from "./elements.module.css";
import { AiFillCloseCircle } from "react-icons/ai";
import ButtonGlobal from "../ButtonGlobal";
import { addUnlockRequestAction } from "../../redux/action/moduleNumberListAction";
import { useDispatch } from "react-redux";
import { useAuthData } from "../../utlis";
import { ToastContainer, toast } from "react-toastify";
const SchoolIdContext = createContext();

export const useSchoolId = () => {
  const [schoolId, setSchoolId] = useState(null);

  const setSelectedSchoolId = (id) => {
    setSchoolId(id);
  };

  return { schoolId, setSelectedSchoolId };
};

export const SchoolIdProvider = ({ children }) => {
  const { schoolId, setSelectedSchoolId } = useSchoolId();

  return (
    <SchoolIdContext.Provider value={{ schoolId, setSelectedSchoolId }}>
      {children}
    </SchoolIdContext.Provider>
  );
};

export const useSchoolIdContext = () => {
  return useContext(SchoolIdContext);
};

/***** Switchs start *******/
export const Switchs = () => {
  const [isChecked, setIsChecked] = useState(false);

  const toggleSwitch = () => {
    setIsChecked(!isChecked);
  };

  return (
    <cite
      className={`${styles.switchs} ${isChecked ? styles.onSw : styles.offSw}`}
      onClick={toggleSwitch}
    >
      <em
        className={`${styles.sliderSw} ${
          isChecked ? styles.onSw : styles.offSw
        }`}
      />
    </cite>
  );
};
/***** Switchs start *******/

/***** sidebar popup start *******/
export const SidebarPopup = ({
  children,
  heading,
  isActive,
  setIsActive,
  flexCenter,
}) => {
  const handleCloseClick = () => {
    setIsActive(false);
  };

  const sidebarClassName = isActive
    ? `${styles.sidebarPopup} ${styles.active}`
    : styles.sidebarPopup;

  // useEffect(()=>{
  //   document.body.classList.toggle(styles.modalActive, isActive);
  //   return ()=>{
  //   document.body.classList.toggle(styles.modalActive, null);
  //   }
  // }, [isActive])

  return (
    <Fragment>
      {isActive && (
        <div className={sidebarClassName}>
          <div
            className={`${styles.sidebarPopupin} ${
              flexCenter && styles.flexCenter
            }`}
          >
            <button onClick={handleCloseClick} className={styles.closed}>
              <AiFillCloseCircle />
            </button>
            {heading && <h2>{heading}</h2>}
            {children}
          </div>
        </div>
      )}
    </Fragment>
  );
};
/***** sidebar popup end *******/

/************Skeleton start***********/
export const SkeletonLoading = () => {
  return (
    <div className={styles.skeletonLoadingMain}>
      {new Array(8).fill("").map((v, i) => (
        <div key={i} className={styles.skeletonLoading}>
          <span className={styles.skeletonLoader}></span>
        </div>
      ))}
    </div>
  );
};

export const SkeletonLoadingSuspense = () => {
  return (
    <div className={`${styles.skeletonLoadingMain} ${styles.skeletonLoadingMainIns}`}>
     
        <div className={styles.skeletonLoading}>
        <img src={process.env.PUBLIC_URL + "/images/cloftLogo.png"} alt="" />
        <span className={styles.skeletonLoader}></span>
       
        </div>

    </div>
  );
};
/************Skeleton end***********/

/************DataNotFounds start***********/
export const DataNotFounds = () => {
  return (
    <div className="bg-white w-full flex items-center justify-center flex-col py-4 rounded-md">
      <label className="flex w-full items-center justify-center pb-3">
        <img src={process.env.PUBLIC_URL + "/images/sidebar/pen.png"} alt="" />
      </label>
      <h3 className="w-full text-center">
        <span className="font-semibold  text-[13px]">Looks like its relaxing day</span> 
        <span className="flex w-[184px] m-[auto] justify-center text-[12px] text-[#999] text-center leading-[18px]">The day is too long so no need to homework today.</span>
      </h3>
    </div>
  );
};
/************DataNotFounds end***********/

/************DataNotFounds start***********/
export const SkeletonNotFound = ({ marginTop, marginBottom }) => {
  const [isLoad, setIsLoad] = useState(false);
  const style = {
    marginTop: marginTop,
    marginBottom: marginBottom,
  };
  useEffect(() => {
    var timer = setTimeout(() => {
      setIsLoad(true);
    }, 1500);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div style={style} className={styles.skeletonNotFound}>
      {!isLoad ? <SkeletonLoading /> : <DataNotFounds />}
    </div>
  );
};
/************DataNotFounds end***********/

/************DataNotFounds start***********/
export const UnlockBox = ({
  active,
  heading = "Ready for more?",
  content,
  children,
  featureName,
  featureKey
}) => {
  const {authList} = useAuthData()
  const dispatch = useDispatch();
  const data = {feature_key: featureKey, feature_name: featureName, school_id: authList?.school_id, raised_by: authList?.first_name, role_name: authList?.role_details?.role_name}

  const handleClick = async () => {
    
    try {
      const successMessage = await dispatch(addUnlockRequestAction(data));
      toast.success(successMessage, {
        autoClose: 1000,
        position: "top-center",
        className: "customToast",
      });
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <Fragment>
      {active ? (
        // <div className={styles.unlockBox}>
        <div id="goPremium" style={{border: "1px solid #f1f1f1", width: "calc(100% + 40px)", height: "calc(100% - 60px)", position: "absolute", left: "-40px", top: "0%", padding: "0px", display: "flex", alignItems: "center", justifyContent: "center", background: "rgba(0, 0, 0, 0.5)", backdropFilter: "blur(8px)", zIndex: 10}}>
          <div className="max-w-[480px] w-full bg-white rounded-lg flex flex-wrap p-5">
           <label className="w-full flex items-center justify-center mb-4">
            <img className="w-[90px]" src={process.env.PUBLIC_URL + "/images/sidebar/diamond.png"} alt="" />
          </label>
          <h1 className="font-semibold text-[28px] text-center w-full pb-2">Go premium!</h1>
          <p className=" text-[13px] text-center opacity-70">Subscribe to our premium package and you’ll unlock a wealth of extra information.</p>
          <ButtonGlobal onClick={handleClick} size="small" className="m-[auto]" width="auto" title="Upgrade today" />
          {/* <div className="w-[480px] bg-white rounded-lg">
          {heading && <h3>{heading}</h3>}
          <aside>
         
          <p>
            {content ||
              "Subscribe to our Premium package and you'll unlock a wealth of extra information."}
          </p>
          {children}
          
          </aside>
          </div> */}
            </div>
        </div>
      ) : null}
        <ToastContainer />
    </Fragment>
  );
};
/************DataNotFounds end***********/

export const useCurrentDateTime = () => {
  const [formattedDateTime, setFormattedDateTime] = useState('');

  useEffect(() => {
    const updateDateTime = () => {
      const currentDate = new Date();
      const options = {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      };

      const formattedDate = new Intl.DateTimeFormat('en-US', options).format(currentDate);
      setFormattedDateTime(formattedDate);
    };

    updateDateTime(); // Call it initially to set the initial value

    const intervalId = setInterval(updateDateTime, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []); // Empty dependency array ensures the effect runs only once on mount

  return formattedDateTime;
};



