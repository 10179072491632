import { useEffect, useState, Suspense, lazy } from "react";
import { Routes, Route, useLocation, useHistory, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";
import { clearTokenAndStorage, useAuthData } from "./utlis";
import { SkeletonLoadingSuspense } from "./component/customeHook/Elements";
import {ToastContainer, toast } from "react-toastify";
import { Helmet } from 'react-helmet'

const Login = lazy(() => import('./pages/Login'));
const Layout = lazy(() => import('./component/Layout'));
const Forgotpassword = lazy(() => import('./pages/Forgotpassword'));
const School = lazy(() => import('./pages/School'));
const AddSchool = lazy(() => import('./pageComponent/schoolDash/AddSchool'));
const Student = lazy(() => import('./pages/Student/index'));
const Teacher = lazy(() => import('./pages/Teacher'));
const PrivateComponent = lazy(() => import('./utlis/PrivateComponent'));
const ErrorPage = lazy(() => import('./pages/Error'));
const SchoolDash = lazy(() => import('./pageComponent/schoolDash/SchoolDash'));
const ClassList = lazy(() => import('./pageComponent/schoolDash/class/ClassList'));
const NewsEvents = lazy(() => import('./pageComponent/schoolDash/news/NewsEvents'));
const SuperAdminDash = lazy(() => import('./pages/Dashboard/SuperAdminDash'));
const StudentDash = lazy(() => import('./pages/Dashboard/StudentDash'));
const TeacherDash = lazy(() => import('./pages/Dashboard/TeacherDash'));
const FeeSetup = lazy(() => import('./pages/Fee/FeeSetup'));
const FeeSubmission = lazy(() => import('./pages/Fee/FeeSubmission'));
const FeePlan = lazy(() => import('./pages/Fee/FeePlan'));
const FeeRequest = lazy(() => import('./pages/Fee/FeeRequest'));
const HolidayManagement = lazy(() => import('./pages/Holiday/HolidayManagement'));
const TransportList = lazy(() => import('./pages/Transport/TransportList'));
const AttendanceManagement = lazy(() => import('./pages/Attendance/AttendanceManagement'));
const AttendanceList = lazy(() => import('./pages/Attendance/AttendanceList'));
const AcademicYearManagement = lazy(() => import('./pages/Academic/AcademicYearManagement'));
const AcademicYearList = lazy(() => import('./pages/Academic/AcademicYearList'));
const AdmissionEnquiryList = lazy(() => import('./pages/AdmissionEnquiry/AdmissionEnquiryList'));
const VisitorList = lazy(() => import('./pages/Visitor/VisitorList'));
const MyProfile = lazy(() => import('./pageComponent/schoolDash/MyProfile'));
const PackageManagementList = lazy(() => import('./pages/PlanSetting/PackageManagementList'));
const ModuleManagementList = lazy(() => import('./pages/PlanSetting/ModuleManagementList'));
const Home = lazy(() => import('./pages/Home'));
const Signup = lazy(() => import('./pages/Signup'));
const StudentAttendanceBulk = lazy(() => import('./pages/StudentAttendance/StudentAttendanceBulk'));
const SubjectManagement = lazy(() => import('./pages/ClassSubject/SubjectManagement'));
const ClassManagement = lazy(() => import('./pages/ClassSubject/ClassManagement'));
const NoticeBoardList = lazy(() => import('./pages/NoticeBoard/NoticeBoardList'));
const UnlockList = lazy(() => import('./pages/umlock/UnlockList'));
const ExamList = lazy(() => import('./pages/Examination/ExamList'));
const AcademicCalender = lazy(() => import('./pages/AcademicCalender'));
const FeeConfiguration = lazy(() => import('./pages/Fee/FeeConfiguration'));
const BookList = lazy(() => import('./pages/Book/BookList'));
const AssignmentList = lazy(() => import('./pages/Assignment/AssignmentList'));
const TimeTableList = lazy(() => import('./pages/TimeTable/TimeTableList'));
const ImportantList = lazy(() => import('./pages/Important/ImportantList'));
const StudentCategoryList = lazy(() => import('./pages/StudentCategory/StudentCategoryList'));
const LeaveManagement  = lazy(()=> import('./pages/Leave/LeaveManagement'))
const SignupList  = lazy(()=> import('./pages/SignupList/SignupList'))
const SupportList  = lazy(()=> import('./pages/SupportList'))

const checkTokenExpiration = () => {
  const token = Cookies.get('jwtToken');
  const currentTime = new Date().getTime();
  const expirationTime = localStorage.getItem('tokenExpiration');
  if (token && expirationTime) {
    if (currentTime > expirationTime) {
      clearTokenAndRedirectToLogin();
    }
  }
};

const clearTokenAndRedirectToLogin = () => {
  toast.error("Token expired. Please login again.", { autoClose: 2000, 
    position: "top-center",
    className: "customToast"
  });

  let timer = setTimeout(() => {
    clearTokenAndStorage()
    clearTimeout(timer)
  }, 2000)
};

const App = () => {
  const { authList } = useAuthData();
  const [isUnlock, setIsUnlock] = useState({});
  const jwtToken = Cookies.get('jwtToken');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const unlocked = localStorage.getItem("unlock");
    setIsUnlock(unlocked ? JSON.parse(unlocked) : {});
  }, [authList?.token]);

  useEffect(() => {
    const tokenExpirationCheckInterval = setInterval(() => {
      checkTokenExpiration();
    }, 60000);
    return () => clearInterval(tokenExpirationCheckInterval);
  }, []);
  
  
    useEffect(() => {
      const isPublicRoute = ['/login', '/signup', '/forgot-password', '/'].includes(location.pathname);
      if (!jwtToken && !isPublicRoute) {
        console.log("ddd")
        localStorage.clear();
        navigate("/");
        window.location.reload();
      }
    }, [jwtToken, location.pathname, navigate]);


  return (
    <>

    <div className="App">
      <Suspense fallback={ <SkeletonLoadingSuspense />}>
      <Routes>
        {!authList?.token ? (
          <>
            <Route exact index path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/forgot-password" element={<Forgotpassword />} />
          </>
        ) : <></>}

        {/*Super admin role */}
        <Route element={<PrivateComponent allowedRoles={[1]} />}>
          <Route element={<Layout />}>
            <Route path="/super-admin-dashboard" element={<SuperAdminDash />} />
            <Route path="/school" element={<School />} />
            <Route path="/add-school" element={<AddSchool />} />
            <Route path="/package-management" element={<PackageManagementList />} />
            <Route path="/module-management" element={<ModuleManagementList />} />
            <Route path="/important"  element={<ImportantList />} />
            <Route path="/signup-list"  element={<SignupList />} />
            <Route path="/support-list"  element={<SupportList />} />
            <Route path="/unlock-leads" element={<UnlockList />} />
            {/* <Route path="/class-subject" element={<ClassSubject />} /> */}
          </Route>
        </Route>

        {/*School Roles */}
        <Route element={<PrivateComponent allowedRoles={[2]} />}>
          <Route element={<Layout />}>
            <Route path="/school-dashboard" element={<SchoolDash moduleNumListsData={isUnlock} />} />
            <Route path="/subject-management" element={<SubjectManagement />} />
            <Route path="/student-category"  element={<StudentCategoryList />} />
           
            <Route path="/student" element={<Student moduleNumListsData={isUnlock} />} />
            <Route path="/teacher" element={<Teacher moduleNumListsData={isUnlock} />} />
            <Route path="/notice-board" element={ <NoticeBoardList moduleNumListsData={isUnlock} /> } />
            <Route path="/news-events" element={<NewsEvents moduleNumListsData={isUnlock} />} />
            <Route path="/holiday" element={<HolidayManagement moduleNumListsData={isUnlock} />} />
            <Route path="/transport" element={<TransportList moduleNumListsData={isUnlock} />} />
            <Route path="/academic-year-list" element={<AcademicYearList moduleNumListsData={isUnlock} />} />
            <Route path="/admission-enquiry" element={<AdmissionEnquiryList moduleNumListsData={isUnlock} />} />
            <Route path="/visitor" element={<VisitorList moduleNumListsData={isUnlock} />} />
            <Route path="/class-list" element={<ClassList />} />
            <Route path="/fee-setup" element={<FeeSetup />} />
            <Route path="/fee-submission" element={<FeeSubmission />} />
            <Route path="/fee-plan" element={<FeePlan />} />
            <Route path="/fee-request" element={<FeeRequest />} />
            <Route path="/my-profile" element={<MyProfile />} />
            
            <Route path="/attendance-list" element={<AttendanceList />} />
            <Route path="/academic-year-management" element={<AcademicYearManagement />} />
            <Route path="/class-management" element={<ClassManagement /> } />
            
            <Route path="/academic-calendar" element={<AcademicCalender />} />
            <Route path="/fee-configuration" element={<FeeConfiguration />} />
            <Route path="/exam-management" element={<ExamList />} />
            <Route path="/attendance-management" element={<AttendanceManagement />} />
            <Route path="/important-days"  element={<ImportantList />} />
            {/* <Route path="/student-attendance" element={<StudentAttendanceBulk />} /> */}
          </Route>
        </Route>

        {/*Teacher */}
        <Route element={<PrivateComponent allowedRoles={[3]} />}>
          <Route element={<Layout />}>
            <Route path="/teacher-dashboard" element={<TeacherDash />} />
            <Route path="/student-management" element={<Student />} />
            <Route path="/subject-management" element={<SubjectManagement />} />
            <Route path="/time-management" element={<TimeTableList />} />
            <Route path="/home-management" element={<AssignmentList />} />
            <Route path="/book-management" element={<BookList />} />
            <Route path="/leave-management" element={<LeaveManagement />} />
            <Route path="/news-event-management" element={<NewsEvents />} />
            <Route path="/notice-management" element={ <NoticeBoardList /> } />
            <Route path="/holiday-management" element={<HolidayManagement />} />
            <Route path="/student-attendance" element={<StudentAttendanceBulk />} />
            <Route path="/teacher-profile" element={<MyProfile />} />
            <Route path="/important-day"  element={<ImportantList />} />
            {/* <Route path="/student-attendance" element={<StudentAttendanceBulk />} /> */}
          </Route>
        </Route>

        {/*Student */}
        <Route element={<PrivateComponent allowedRoles={[4]} />}>
          <Route element={<Layout />}>
            <Route path="/student-dashboard" element={<StudentDash />} />
          </Route>
        </Route>

        <Route path="*" exact={true} element={<ErrorPage />} />
      </Routes>
      </Suspense>
    </div>



{/* <ToastContainer /> */}
    </>
          

  );
};

export default App;

// role id base
// 1 Super admin
// 2 School admin
// 3 Teacher
// 4 Student
