
export const BASE_URL = "https://api.cloftware.com/api"

// "https://ed0a-116-74-58-92.ngrok-free.app/api"
// "https://api.cloftware.com/api"
export const BASE_URL_SCHOOL = BASE_URL + "/school"
// process.env.REACT_APP_BASE_URL

export const BATCHCLASS_LIST_URL = "/fees/get_fee_class_list";
export const BATCHCLASS_LIST_SUCCESS = "BATCHCLASS_LIST_SUCCESS";
export const BATCHCLASS_LIST_ERROR = "BATCHCLASS_LIST_ERROR";

export const BATCHCLASS_TEACHER_LIST_URL = "/teacher/get_teacher_classes";
export const BATCHCLASS_TEACHER_LIST_SUCCESS = "BATCHCLASS_TEACHER_LIST_SUCCESS";
export const BATCHCLASS_TEACHER_LIST_ERROR = "BATCHCLASS_TEACHER_LIST_ERROR";

export const BATCHSECTION_LIST_URL = "/class/class_details";
export const BATCHSECTION_LIST_SUCCESS = "BATCHSECTION_LIST_SUCCESS";
export const BATCHSECTION_LIST_ERROR = "BATCHSECTION_LIST_ERROR";

export const SUBJECT_LIST_URL = "/subject/get_subject_list";
export const SUBJECT_LIST_SUCCESS = "SUBJECT_LIST_SUCCESS";
export const SUBJECT_LIST_ERROR = "SUBJECT_LIST_ERROR";

export const TEACHER_LIST_URL = "/teacher/get_teachers_list";
export const TEACHER_LIST_SUCCESS = "TEACHER_LIST_SUCCESS";
export const TEACHER_LIST_ERROR = "TEACHER_LIST_ERROR";

export const TEACHER_DETAILS_LIST_URL = "/teacher/get_teacher_details";
export const TEACHER_DETAILS_LIST_SUCCESS = "TEACHER_DETAILS_LIST_SUCCESS";
export const TEACHER_DETAILS_LIST_ERROR = "TEACHER_DETAILS_LIST_ERROR";


export const STUDENT_LIST_URL = "/student/get_students_list";
export const STUDENT_LIST_SUCCESS = "STUDENT_LIST_SUCCESS";
export const STUDENT_LIST_ERROR = "STUDENT_LIST_ERROR";

export const STUDENT_SCHOOL_LIST_URL = "/student/school_student_list";
export const STUDENT_SCHOOL_LIST_SUCCESS = "STUDENT_SCHOOL_LIST_SUCCESS";
export const STUDENT_SCHOOL_LIST_ERROR = "STUDENT_SCHOOL_LIST_ERROR";


export const SCHOOL_LIST_URL = "/get_school_list";
export const SCHOOL_LIST_SUCCESS = "SCHOOL_LIST_SUCCESS";
export const SCHOOL_LIST_ERROR = "SCHOOL_LIST_ERROR";

export const ENQUERY_LIST_URL = "/enquiry/get_enquiry_list";
export const ENQUERY_LIST_SUCCESS = "ENQUERY_LIST_SUCCESS";
export const ENQUERY_LIST_ERROR = "ENQUERY_LIST_ERROR";

export const VISITOR_LIST_URL = "/visitor/get_visitors_list";
export const VISITOR_LIST_SUCCESS = "VISITOR_LIST_SUCCESS";
export const VISITOR_LIST_ERROR = "VISITOR_LIST_ERROR";

export const VISITOR_MARKED_LIST_URL = "/visitor/marked_exists_visitor";
export const VISITOR_MARKED_LIST_SUCCESS = "VISITOR_MARKED_LIST_SUCCESS";
export const VISITOR_MARKED_LIST_ERROR = "VISITOR_MARKED_LIST_ERROR";


export const EVENT_LIST_URL = "/event/get_event_list";
export const EVENT_LIST_SUCCESS = "EVENT_LIST_SUCCESS";
export const EVENT_LIST_ERROR = "EVENT_LIST_ERROR";

export const HOLIDAY_LIST_URL = "/holiday/get_holiday_list";
export const HOLIDAY_LIST_SUCCESS = "HOLIDAY_LIST_SUCCESS";
export const HOLIDAY_LIST_ERROR = "HOLIDAY_LIST_ERROR";

export const TRANSPORT_LIST_URL = "/transport/get_transport_list";
export const TRANSPORT_LIST_SUCCESS = "TRANSPORT_LIST_SUCCESS";
export const TRANSPORT_LIST_ERROR = "TRANSPORT_LIST_ERROR";

/*****ACADEMIC_YEAR start*****/
export const ACADEMIC_YEAR_LIST_URL = "/year/get_year_listing";
export const ACADEMIC_YEAR_LIST_SUCCESS = "ACADEMIC_YEAR_LIST_SUCCESS";
export const ACADEMIC_YEAR_LIST_ERROR = "ACADEMIC_YEAR_LIST_ERROR";

export const ACADEMIC_YEAR_ADDEDIT_URL = "/year/add_edit_year";
export const ACADEMIC_YEAR_ADDEDIT_SUCCESS = "ACADEMIC_YEAR_ADDEDIT_SUCCESS";
export const ACADEMIC_YEAR_ADDEDIT_ERROR = "ACADEMIC_YEAR_ADDEDIT_ERROR";
/*****ACADEMIC_YEAR end*****/

export const TEACHER_TEACHING_LIST_URL = "/get_common_teacher_list"
export const TEACHER_TEACHING_LIST_SUCCESS = "TEACHER_TEACHING_LIST_SUCCESS";
export const TEACHER_TEACHING_LIST_ERROR = "TEACHER_TEACHING_LIST_ERROR";


export const CLASS_LIST_URL = "/class/get_class_list";
export const CLASS_LIST_SUCCESS = "CLASS_LIST_SUCCESS";
export const CLASS_LIST_ERROR = "CLASS_LIST_ERROR";


export const CLASSDEFAULT_SUBJECT_LIST_URL = "/class/add_class_default_sujects";
export const CLASSDEFAULT_SUBJECT_LIST_SUCCESS = "CLASSDEFAULT_SUBJECT_LIST_SUCCESS";
export const CLASSDEFAULT_SUBJECT_LIST_ERROR = "CLASSDEFAULT_SUBJECT_LIST_ERROR";

export const CLASSWISE_SUBJECT_LIST_URL = "/class/get_classwise_subjects";
export const CLASSWISE_SUBJECT_LIST_SUCCESS = "CLASSWISE_SUBJECT_LIST_SUCCESS";
export const CLASSWISE_SUBJECT_LIST_ERROR = "CLASSWISE_SUBJECT_LIST_ERROR";

export const ASSIGNCLASS_TEACHER_LIST_URL = "/class/assign_class_subject_teacher";
export const ASSIGNCLASS_TEACHER_LIST_SUCCESS = "ASSIGNCLASS_TEACHER_LIST_SUCCESS";
export const ASSIGNCLASS_TEACHER_LIST_ERROR = "ASSIGNCLASS_TEACHER_LIST_ERROR";

export const CLASS_SECTION_LIST_URL = "/class/get_section_class_list";
export const CLASS_SECTION_LIST_SUCCESS = "CLASS_SECTION_LIST_SUCCESS";
export const CLASS_SECTION_LIST_ERROR = "CLASS_SECTION_LIST_ERROR";

export const EXPORT_STUDENT_LIST_URL = "/student/export_student_excel";
export const EXPORT_STUDENT_LIST_SUCCESS = "EXPORT_STUDENT_LIST_SUCCESS";
export const EXPORT_STUDENT_LIST_ERROR = "EXPORT_STUDENT_LIST_ERROR";

export const NOTICE_BOARD_LIST_URL = "/notice/get_notice_list";
export const NOTICE_BOARD_LIST_SUCCESS = "NOTICE_BOARD_LIST_SUCCESS";
export const NOTICE_BOARD_LIST_ERROR = "NOTICE_BOARD_LIST_ERROR";

export const PACKAGE_LIST_URL = "/package/package_list";
export const PACKAGE_LIST_SUCCESS = "PACKAGE_LIST_SUCCESS";
export const PACKAGE_LIST_ERROR = "PACKAGE_LIST_ERROR";

export const MODULE_LIST_URL = "/module/module_list";
export const MODULE_LIST_SUCCESS = "MODULE_LIST_SUCCESS";
export const MODULE_LIST_ERROR = "MODULE_LIST_ERROR";

export const MODULE_NUMBER_LIST_URL = "/module/get_school_module_list";
export const MODULE_NUMBER_LIST_SUCCESS = "MODULE_NUMBER_LIST_SUCCESS";
export const MODULE_NUMBER_LIST_ERROR = "MODULE_NUMBER_LIST_ERROR";

export const ADD_UNLOCK_REQUEST_URL = "/school/add_unlock_request";
export const ADD_UNLOCK_REQUEST_SUCCESS = "ADD_UNLOCK_REQUEST_SUCCESS";
export const ADD_UNLOCK_REQUEST_ERROR = "ADD_UNLOCK_REQUEST_ERROR";

export const GET_UNLOCK_LIST_URL = "/school/get_unlock_request";
export const GET_UNLOCK_LIST_SUCCESS = "GET_UNLOCK_LIST_SUCCESS";
export const GET_UNLOCK_LIST_ERROR = "GET_UNLOCK_LIST_ERROR";

export const FEES_LIST_URL = "/fees/fee_config_list";
export const FEES_LIST_SUCCESS = "FEES_LIST_SUCCESS";
export const FEES_LIST_ERROR = "FEES_LIST_ERROR";

export const FEES_GET_RECORD_LIST_URL = "/fees/get_fee_record";
export const FEES_GET_RECORD_LIST_SUCCESS = "FEES_GET_RECORD_LIST_SUCCESS";
export const FEES_GET_RECORD_LIST_ERROR = "FEES_GET_RECORD_LIST_ERROR";

export const ASSIGNMENT_LIST_URL = "/assignment/get_assignment_list";
export const ASSIGNMENT_LIST_SUCCESS = "ASSIGNMENT_LIST_SUCCESS";
export const ASSIGNMENT_LIST_ERROR = "ASSIGNMENT_LIST_ERROR";

export const BOOK_LIST_URL = "/book/get_book_schedule_list";
export const BOOK_LIST_SUCCESS = "BOOK_LIST_SUCCESS";
export const BOOK_LIST_ERROR = "BOOK_LIST_ERROR";

export const TEACHER_ASSIGN_SUBJECT_LIST_URL = "/teacher/assigned_subject_list";
export const TEACHER_ASSIGN_SUBJECT_LIST_SUCCESS = "TEACHER_ASSIGN_SUBJECT_LIST_SUCCESS";
export const TEACHER_ASSIGN_SUBJECT_LIST_ERROR = "TEACHER_ASSIGN_SUBJECT_LIST_ERROR";

export const TIME_LIST_URL = "/time/get_time_table_list";
export const TIME_LIST_SUCCESS = "TIME_LIST_SUCCESS";
export const TIME_LIST_ERROR = "TIME_LIST_ERROR";

export const IMPORTAN_LIST_URL = "/important/get_list";
export const IMPORTAN_LIST_SUCCESS = "IMPORTAN_LIST_SUCCESS";
export const IMPORTAN_LIST_ERROR = "IMPORTAN_LIST_ERROR";

export const SIGNUP_USER_LIST_URL = "/sign_up_user_list";
export const SIGNUP_USER_LIST_SUCCESS = "SIGNUP_USER_LIST_SUCCESS";
export const SIGNUP_USER_LIST_ERROR = "SIGNUP_USER_LIST_ERROR";

export const SUPPORT_LIST_URL = "/support/support_ticket_list";
export const SUPPORT_LIST_SUCCESS = "SUPPORT_LIST_SUCCESS";
export const SUPPORT_LIST_ERROR = "SUPPORT_LIST_ERROR";

export const STUDENT_CATEGORY_LIST_URL = "/stu_category/get_category_list";
export const STUDENT_CATEGORY_LIST_SUCCESS = "STUDENT_CATEGORY_LIST_SUCCESS";
export const STUDENT_CATEGORY_LIST_ERROR = "STUDENT_CATEGORY_LIST_ERROR";

export const STUDENT_DETAILS_LIST_URL = "/student/get_student_details";
export const STUDENT_DETAILS_LIST_SUCCESS = "STUDENT_DETAILS_LIST_SUCCESS";
export const STUDENT_DETAILS_LIST_ERROR = "STUDENT_DETAILS_LIST_ERROR";

export const SUBJECT_TEACHER_LIST_URL = "/teacher/subject_assign_teacher_list";
export const SUBJECT_TEACHER_LIST_SUCCESS = "SUBJECT_TEACHER_LIST_SUCCESS";
export const SUBJECT_TEACHER_LIST_ERROR = "SUBJECT_TEACHER_LIST_ERROR";


export const CLASS_SECTION_TEACHER_LIST_URL = "/teacher/get_class_sections";
export const CLASS_SECTION_TEACHER_LIST_SUCCESS = "CLASS_SECTION_TEACHER_LIST_SUCCESS";
export const CLASS_SECTION_TEACHER_LIST_ERROR = "CLASS_SECTION_TEACHER_LIST_ERROR";

export const LEAVE_TEACHER_LIST_URL = "/teacher/get_leave_list";
export const LEAVE_TEACHER_LIST_SUCCESS = "LEAVE_TEACHER_LIST_SUCCESS";
export const LEAVE_TEACHER_LIST_ERROR = "LEAVE_TEACHER_LIST_ERROR";

export const COMMON_TEACHER_LIST_URL = "/get_common_teacher_list";
export const COMMON_TEACHER_LIST_SUCCESS = "COMMON_TEACHER_LIST_SUCCESS";
export const COMMON_TEACHER_LIST_ERROR = "COMMON_TEACHER_LIST_ERROR";

export const SCHOOL_DASHBOARD_LIST_URL = "/school/school_dashboard";
export const SCHOOL_DASHBOARD_LIST_SUCCESS = "SCHOOL_DASHBOARD_LIST_SUCCESS";
export const SCHOOL_DASHBOARD_LIST_ERROR = "SCHOOL_DASHBOARD_LIST_ERROR";
















