import { combineReducers } from "redux";
// import getResellerByUtiReducer from "./getResellerDataReducer";
import batchClassReducer from "./batchClassReducer";
import subjectListReducer from "./subjectListReducer";
import teacherListReducer from "./teacherListReducer";
import feesListReducer from "./feesListReducer";
import eventListReducer from "./eventListReducer";
import holidayListReducer from "./holidayListReducer";
import transportListReducer from "./transportListReducer";
import academicYearListReducer from "./academicYearListReducer";
import teacherTeachingListReducer from "./teacherTeachingListReducer";
import classListReducer from "./classListReducer";
import studentListReducer from "./studentListReducer";
import enquiryListReducer from "./enquiryListReducer";
import visitorListReducer from "./visitorListReducer";
import schoolListReducer from "./schoolListReducer";
import noticeBoardListReducer from "./noticeBoardListReducer";
import packageListReducer from "./packageListReducer";
import moduleListReducer from "./moduleListReducer";
import moduleNumberListReducer from "./moduleNumberListReducer";
import assignmentListReducer from "./assignmentListReducer";
import bookListReducer from "./bookListReducer";
import timeTableListReducer from "./timeTableListReducer";
import importantListReducer from "./importantListReducer"
import globalDataList from "./customData/globalDataList";

const appReducer = combineReducers({
subjectListData:subjectListReducer,
batchClassData:batchClassReducer,
teacherListData: teacherListReducer,
feesListData: feesListReducer,
eventListData : eventListReducer,
holidayListData : holidayListReducer,
transportListData : transportListReducer,
academicYearListData : academicYearListReducer,
teacherTeachListData : teacherTeachingListReducer,
classListData : classListReducer,
studentListData : studentListReducer,
enquiryListData : enquiryListReducer,
visitorListData : visitorListReducer,
schoolListData : schoolListReducer,
noticeBoardListData : noticeBoardListReducer,
packageListData : packageListReducer,
moduleListData : moduleListReducer,
moduleNumberListData : moduleNumberListReducer,
assignmentListData : assignmentListReducer,
bookListData : bookListReducer,
timeTableListData : timeTableListReducer,
importantListData : importantListReducer,
globalData: globalDataList, 

});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;