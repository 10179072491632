import {BATCHCLASS_LIST_SUCCESS, BATCHCLASS_TEACHER_LIST_SUCCESS, BATCHSECTION_LIST_SUCCESS, CLASS_SECTION_TEACHER_LIST_SUCCESS, LEAVE_TEACHER_LIST_SUCCESS, COMMON_TEACHER_LIST_SUCCESS, SCHOOL_DASHBOARD_LIST_SUCCESS} from '../constants/constants';

const initialState = {
  childListData: {
    data: {},
    success: false
  },
  teacherClassListData: {
    data: {},
    success: false
  },
  sectionListData: {
    data: {},
    success: false
  },
  teacherClassSectionListData: {
    data: {},
    success: false
  },
  leaveListData: {
    data: {},
    success: false
  },
  commonTeacherListData: {
    data: {},
    success: false
  },
  schoolDashListData: {
    data: {},
    success: false
  },
};

const batchClassReducer = (state = initialState, action) => {
  switch (action.type) {
    case BATCHCLASS_LIST_SUCCESS:
      return {
        ...state,
        childListData: {
          ...state.childListData,
          data: action.payload
        }
      };
      case BATCHCLASS_TEACHER_LIST_SUCCESS:
        return {
          ...state,
          teacherClassListData: {
            ...state.teacherClassListData,
            data: action.payload
          }
        };
      case BATCHSECTION_LIST_SUCCESS:
        return {
          ...state,
          sectionListData: {
            ...state.sectionListData,
            data: action.payload
          }
        };
        case CLASS_SECTION_TEACHER_LIST_SUCCESS:
          return {
            ...state,
            teacherClassSectionListData: {
              ...state.teacherClassSectionListData,
              data: action.payload
            }
          };
          case LEAVE_TEACHER_LIST_SUCCESS:
            return {
              ...state,
              leaveListData: {
                ...state.leaveListData,
                data: action.payload
              }
            };
            case COMMON_TEACHER_LIST_SUCCESS:
              return {
                ...state,
                commonTeacherListData: {
                  ...state.commonTeacherListData,
                  data: action.payload
                }
              };
              case SCHOOL_DASHBOARD_LIST_SUCCESS:
              return {
                ...state,
                schoolDashListData: {
                  ...state.schoolDashListData,
                  data: action.payload
                }
              };
    default:
      return state;
  }
};

export default batchClassReducer;
