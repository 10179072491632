import { createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";

const initialState = {
    teacClassSectionData: null
}

export const globalDataList = createSlice({
    name: "globalData",
    initialState,
    reducers:{
      setTeacClassSectionData: (state, action) =>{
        state.teacClassSectionData = action.payload
      }
    }
});

export const { setTeacClassSectionData } = globalDataList.actions;

export default globalDataList.reducer;