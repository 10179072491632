import {MODULE_LIST_SUCCESS} from '../constants/constants';

const initialState = {
  moduleListingData: {
    data: {},
    success: false
  }
};

const moduleListReducer = (state = initialState, action) => {
  switch (action.type) {
    case MODULE_LIST_SUCCESS:
      return {
        ...state,
        moduleListingData: {
          ...state.moduleListingData,
          data: action.payload
        }
      };
    default:
      return state;
  }
};

export default moduleListReducer;
