import {MODULE_NUMBER_LIST_SUCCESS, ADD_UNLOCK_REQUEST_SUCCESS, GET_UNLOCK_LIST_SUCCESS} from '../constants/constants';

const initialState = {
  moduleNumberListingData: {
    data: {},
    success: false
  },
  addUnlockData: {
    data: {},
    success: false
  },
  getUnlockData: {
    data: {},
    success: false
  },
};

const moduleNumberListReducer = (state = initialState, action) => {
  switch (action.type) {
    case MODULE_NUMBER_LIST_SUCCESS:
      return {
        ...state,
        moduleNumberListingData: {
          ...state.moduleNumberListingData,
          data: action.payload
        }
      };
      case ADD_UNLOCK_REQUEST_SUCCESS:
        return {
          ...state,
          addUnlockData: {
            ...state.addUnlockData,
            data: action.payload
          }
        };
        case GET_UNLOCK_LIST_SUCCESS:
          return {
            ...state,
            getUnlockData: {
              ...state.getUnlockData,
              data: action.payload
            }
          };
               case GET_UNLOCK_LIST_SUCCESS:
          return {
            ...state,
            getUnlockData: {
              ...state.getUnlockData,
              data: action.payload
            }
          };
    default:
      return state;
  }
};

export default moduleNumberListReducer;
