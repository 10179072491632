import {SCHOOL_LIST_SUCCESS} from '../constants/constants';

const initialState = {
  schoolListingData: {
    data: {},
    success: false
  },
};

const schoolListReducer = (state = initialState, action) => {
  switch (action.type) {
    case SCHOOL_LIST_SUCCESS:
      return {
        ...state,
        schoolListingData: {
          ...state.schoolListingData,
          data: action.payload
        }
      };
      
    default:
      return state;
  }
};

export default schoolListReducer;
