
import {CLASS_LIST_SUCCESS, CLASSDEFAULT_SUBJECT_LIST_SUCCESS, CLASSWISE_SUBJECT_LIST_SUCCESS, ASSIGNCLASS_TEACHER_LIST_SUCCESS, CLASS_SECTION_LIST_SUCCESS, SUBJECT_TEACHER_LIST_SUCCESS} from '../constants/constants';

const initialState = {
  classListingData: {
    data: {},
    success: false
  },
  classDefaultSubjectData: {
    data: {},
    success: false
  },
  classWiseSubjectData: {
    data: {},
    success: false
  },
  assignClassTeacherData: {
    data: {},
    success: false
  },
  classSectionBulkData: {
    data: {},
    success: false
  },
  subjectTeacherData: {
    data: {},
    success: false
  },
  
};

const classListReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLASS_LIST_SUCCESS:
      return {
        ...state,
        classListingData: {
          ...state.classListingData,
          data: action.payload,
          success: true
        }
      };
      case CLASSDEFAULT_SUBJECT_LIST_SUCCESS:
        return {
          ...state,
          classDefaultSubjectData: {
            ...state.classDefaultSubjectData,
            data: action.payload,
            success: true
          }
        };
        case CLASSWISE_SUBJECT_LIST_SUCCESS:
          return {
            ...state,
            classWiseSubjectData: {
              ...state.classWiseSubjectData,
              data: action.payload,
              success: true
            }
          };
          case ASSIGNCLASS_TEACHER_LIST_SUCCESS:
          return {
            ...state,
            assignClassTeacherData: {
              ...state.assignClassTeacherData,
              data: action.payload,
              success: true
            }
          };
          case SUBJECT_TEACHER_LIST_SUCCESS:
            return {
              ...state,
              subjectTeacherData: {
                ...state.subjectTeacherData,
                data: action.payload,
                success: true
              }
            };
          case CLASS_SECTION_LIST_SUCCESS:
            return {
              ...state,
              classSectionBulkData: {
                ...state.classSectionBulkData,
                data: action.payload,
                success: true
              }
            };
    default:
      return state;
  }
};

export default classListReducer;
