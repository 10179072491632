import {PACKAGE_LIST_SUCCESS} from '../constants/constants';

const initialState = {
  reducerListingData: {
    data: {},
    success: false
  },
};

const packageListReducer = (state = initialState, action) => {
  switch (action.type) {
    case PACKAGE_LIST_SUCCESS:
      return {
        ...state,
        reducerListingData: {
          ...state.reducerListingData,
          data: action.payload
        }
      };
    default:
      return state;
  }
};

export default packageListReducer;
