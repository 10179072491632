import {VISITOR_LIST_SUCCESS, VISITOR_MARKED_LIST_SUCCESS} from '../constants/constants';

const initialState = {
  visitorListingData: {
    data: {},
    success: false
  },
  visitorMarkedListingData: {
    data: {},
    success: false
  },
};

const visitorListReducer = (state = initialState, action) => {
  switch (action.type) {
    case VISITOR_LIST_SUCCESS:
      return {
        ...state,
        visitorListingData: {
          ...state.visitorListingData,
          data: action.payload
        }
      };
      case VISITOR_MARKED_LIST_SUCCESS:
  return {
    ...state,
    visitorMarkedListingData: {
      ...state.visitorMarkedListingData,
      data: action.payload
    }
  };
    default:
      return state;
  }
};

export default visitorListReducer;
