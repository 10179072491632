import {FEES_LIST_SUCCESS, FEES_GET_RECORD_LIST_SUCCESS} from '../constants/constants';

const initialState = {
  feesListingData: {
    data: {},
    success: false
  },
  feesGetRecordListingData: {
    data: {},
    success: false
  },
};

const feesListReducer = (state = initialState, action) => {
  switch (action.type) {
    case FEES_LIST_SUCCESS:
      return {
        ...state,
        feesListingData: {
          ...state.feesListingData,
          data: action.payload
        }
      };
      case FEES_GET_RECORD_LIST_SUCCESS:
        return {
          ...state,
          feesGetRecordListingData: {
            ...state.feesGetRecordListingData,
            data: action.payload
          }
        };
    default:
      return state;
  }
};

export default feesListReducer;
