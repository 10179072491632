import {TEACHER_LIST_SUCCESS, TEACHER_DETAILS_LIST_SUCCESS} from '../constants/constants';

const initialState = {
  teacherListingData: {
    data: {},
    success: false
  },
  teacherdetailListingData: {
    data: {},
    success: false
  },
};

const teacherListReducer = (state = initialState, action) => {
  switch (action.type) {
    case TEACHER_LIST_SUCCESS:
      return {
        ...state,
        teacherListingData: {
          ...state.teacherListingData,
          data: action.payload
        }
      };
      case TEACHER_DETAILS_LIST_SUCCESS:
        return {
          ...state,
          teacherDetailListingData: {
            ...state.teacherDetailListingData,
            data: action.payload
          }
        };
    default:
      return state;
  }
};

export default teacherListReducer;
