import {BOOK_LIST_SUCCESS, TEACHER_ASSIGN_SUBJECT_LIST_SUCCESS} from '../constants/constants';

const initialState = {
  bookListingData: {
    data: {},
    success: false
  },
  teacherSubjectListingData: {
    data: {},
    success: false
  },
};

const bookListReducer = (state = initialState, action) => {
  switch (action.type) {
    case BOOK_LIST_SUCCESS:
      return {
        ...state,
        bookListingData: {
          ...state.bookListingData,
          data: action.payload
        }
      };
      case TEACHER_ASSIGN_SUBJECT_LIST_SUCCESS:
        return {
          ...state,
          teacherSubjectListingData: {
            ...state.teacherSubjectListingData,
            data: action.payload
          }
        };
    default:
      return state;
  }
};

export default bookListReducer;
