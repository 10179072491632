import {IMPORTAN_LIST_SUCCESS, STUDENT_CATEGORY_LIST_SUCCESS, SIGNUP_USER_LIST_SUCCESS, SUPPORT_LIST_SUCCESS, STUDENT_DETAILS_LIST_SUCCESS} from '../constants/constants';

const initialState = {
  importantListingData: {
    data: {},
    success: false
  },
  studentCategoryListingData: {
    data: {},
    success: false
  },
  signupListListingData: {
    data: {},
    success: false
  },
  supportListListingData: {
    data: {},
    success: false
  },
  studentDetailsListListingData: {
    data: {},
    success: false
  },
};

const importantListReducer = (state = initialState, action) => {
  switch (action.type) {
    case IMPORTAN_LIST_SUCCESS:
      return {
        ...state,
        importantListingData: {
          ...state.importantListingData,
          data: action.payload
        }
      };
      case STUDENT_CATEGORY_LIST_SUCCESS:
        return {
          ...state,
          studentCategoryListingData: {
            ...state.studentCategoryListingData,
            data: action.payload
          }
        };
        case SIGNUP_USER_LIST_SUCCESS:
          return {
            ...state,
            signupListListingData: {
              ...state.signupListListingData,
              data: action.payload
            }
          };
            case SUPPORT_LIST_SUCCESS:
    return {
      ...state,
      supportListListingData: {
        ...state.supportListListingData,
        data: action.payload
      }
    };
    case STUDENT_DETAILS_LIST_SUCCESS:
      return {
        ...state,
        studentDetailsListListingData: {
          ...state.studentDetailsListListingData,
          data: action.payload
        }
      };
    default:
      return state;
  }
};

export default importantListReducer;
