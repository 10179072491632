import {ENQUERY_LIST_SUCCESS} from '../constants/constants';

const initialState = {
  enquiryListingData: {
    data: {},
    success: false
  },
};

const enquiryListReducer = (state = initialState, action) => {
  switch (action.type) {
    case ENQUERY_LIST_SUCCESS:
      return {
        ...state,
        enquiryListingData: {
          ...state.enquiryListingData,
          data: action.payload
        }
      };
    default:
      return state;
  }
};

export default enquiryListReducer;
