import {ACADEMIC_YEAR_LIST_SUCCESS, ACADEMIC_YEAR_ADDEDIT_SUCCESS} from '../constants/constants';

const initialState = {
  academicYearListingData: {
    data: {},
    success: false
  },
  academicYearAddEditData: {
    data: {},
    success: false
  },
};

const academicYearListReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACADEMIC_YEAR_LIST_SUCCESS:
      return {
        ...state,
        academicYearListingData: {
          ...state.academicYearListingData,
          data: action.payload
        }
      };
      case ACADEMIC_YEAR_ADDEDIT_SUCCESS:
        return {
          ...state,
          academicYearAddEditData: {
            ...state.academicYearAddEditData,
            data: action.payload
          }
        };
    default:
      return state;
  }
};

export default academicYearListReducer;
