import {ASSIGNMENT_LIST_SUCCESS} from '../constants/constants';

const initialState = {
  assignmentListingData: {
    data: {},
    success: false
  },
};

const assignmentListReducer = (state = initialState, action) => {
  switch (action.type) {
    case ASSIGNMENT_LIST_SUCCESS:
      return {
        ...state,
        assignmentListingData: {
          ...state.assignmentListingData,
          data: action.payload
        }
      };
    default:
      return state;
  }
};

export default assignmentListReducer;
