import {TRANSPORT_LIST_SUCCESS} from '../constants/constants';

const initialState = {
  transportListingData: {
    data: {},
    success: false
  },
};

const transportListReducer = (state = initialState, action) => {
  switch (action.type) {
    case TRANSPORT_LIST_SUCCESS:
      return {
        ...state,
        transportListingData: {
          ...state.transportListingData,
          data: action.payload
        }
      };
    default:
      return state;
  }
};

export default transportListReducer;
