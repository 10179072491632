import {NOTICE_BOARD_LIST_SUCCESS} from '../constants/constants';

const initialState = {
  noticeBoardListingData: {
    data: {},
    success: false
  },
};

const noticeBoardListReducer = (state = initialState, action) => {
  switch (action.type) {
    case NOTICE_BOARD_LIST_SUCCESS:
      return {
        ...state,
        noticeBoardListingData: {
          ...state.noticeBoardListingData,
          data: action.payload
        }
      };
    default:
      return state;
  }
};

export default noticeBoardListReducer;
