import {STUDENT_LIST_SUCCESS, STUDENT_SCHOOL_LIST_SUCCESS} from '../constants/constants';

const initialState = {
  studentListingData: {
    data: {},
    success: false
  },
  studentSchoolListingData: {
    data: {},
    success: false
  },
};

const studentListReducer = (state = initialState, action) => {
  switch (action.type) {
    case STUDENT_LIST_SUCCESS:
      return {
        ...state,
        studentListingData: {
          ...state.studentListingData,
          data: action.payload
        }
      };
      case STUDENT_SCHOOL_LIST_SUCCESS:
  return {
    ...state,
    studentSchoolListingData: {
      ...state.studentSchoolListingData,
      data: action.payload
    }
  };
    default:
      return state;
  }
};

export default studentListReducer;
