
import {TEACHER_TEACHING_LIST_SUCCESS} from '../constants/constants';

const initialState = {
  teacherTeachListingData: {
    data: {},
    success: false
  },
};

const teacherTeachingListReducer = (state = initialState, action) => {
  switch (action.type) {
    case TEACHER_TEACHING_LIST_SUCCESS:
      return {
        ...state,
        teacherTeachListingData: {
          ...state.teacherTeachListingData,
          data: action.payload
        }
      };
    default:
      return state;
  }
};

export default teacherTeachingListReducer;
